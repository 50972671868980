export default {
  headers: [
    {
      id: 'top-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/tijat/logo.webp',
        url: 'https://www.moniquesells.com/',
      },
      breakpoint: 0,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-tijat-top-header',
        headerSectionsClassName: 'on-tijat-top-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-tijat-top-header-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'tijat-face',
            url: 'https://www.facebook.com/monique.walker.54922',
            iconSet: 'fab',
            className: 'facebook',
          },
          {
            id: 'tijat-insta',
            url: 'https://www.instagram.com/mosellsaz/',
            iconSet: 'fab',
            className: 'instagram',
          },
          {
            id: 'tijat-linked',
            url: 'https://www.linkedin.com/in/MoniqueWalkerSells',
            iconSet: 'fab',
            className: 'linkedin-in',
          },
          {
            id: 'tijat-pinterest',
            url: 'https://www.pinterest.com/mosellsaz/',
            iconSet: 'fab',
            className: 'pinterest',
          },
          {
            id: 'tijat-twitter',
            url: 'https://twitter.com/MoniqueSellsAZ',
            iconSet: 'fab',
            className: 'twitter',
          },
          {
            id: 'tijat-youtube',
            url: 'https://www.youtube.com/channel/UCkcX2e8dZyZIjT2yqhjfQhA',
            iconSet: 'fab',
            className: 'youtube',
          },
          {
            id: 'tijat-envelope',
            url: 'mailto:Monique@MoniqueSells.com',
            iconSet: 'fas',
            className: 'envelope',
          },
          {
            id: 'tijat-mobile-icon',
            url: 'tel:+1-602-413-8195',
            iconSet: 'fas',
            className: 'mobile',
          },
          {
            id: 'tijat-top-header-phone-number',
            content: '602-413-8195',
            url: 'tel:+1-602-413-8195',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/tijat/logo.webp',
        url: 'https://www.moniquesells.com/',
      },
      breakpoint: 1059,
      desktopMenu: {
        type: 'one-offs', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: 'on-tijat-logo',
        },
        headerClassName: 'on-tijat-header',
        headerSectionsClassName: 'on-tijat-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-tijat-header-item',
        menuClassName: 'on-tijat-header-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-tijat-header-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'tijat-desktop-home-item',
            content: 'Home',
            url: 'https://www.moniquesells.com/',
          },
          {
            id: 'tijat-cash-offer-desktop',
            content: 'Cash Offer',
            url: 'https://cashoffer.moniquesells.com',
          },
          {
            id: 'tijat-trade-in-desktop',
            content: 'Trade In',
            url: 'https://trade-in.moniquesells.com',
          },
          {
            id: 'tijat-desktop-about-item',
            content: 'About',
            url: 'https://www.moniquesells.com/about-monique/',
            menuItems: [
              {
                id: 'tijat-desktop-about-monique',
                content: 'Monique',
                url: 'https://www.moniquesells.com/about-monique/',
              },
              {
                id: 'tijat-desktop-about-team',
                content: 'About Team',
                url: 'https://www.moniquesells.com/about-team/',
              },
            ],
          },
          {
            id: 'tijat-desktop-listings',
            content: 'Listings',
            url: 'https://www.moniquesells.com/listings/',
          },
          {
            id: 'tijat-desktop-properties',
            content: 'Properties',
            url: 'https://www.moniquesells.com/properties/',
            menuItems: [
              {
                id: 'tijat-desktop-properties-biltmore-arcadia',
                content: 'Biltmore - Arcadia Properties for Sale',
                url: 'https://www.moniquesells.com/biltmore-arcadia/',
              },
              {
                id: 'tijat-desktop-properties-northeast-valley',
                content: 'Northeast Valley Properties for Sale',
                url: 'https://www.moniquesells.com/northeast-valley/',
              },
              {
                id: 'tijat-desktop-properties-northwest-valley',
                content: 'Northwest Valley Properties for Sale',
                url: 'https://www.moniquesells.com/northwest-valley/',
              },
              {
                id: 'tijat-desktop-properties-phoenix',
                content: 'Phoenix Properties for Sale',
                url: 'https://www.moniquesells.com/phoenix/',
              },
              {
                id: 'tijat-desktop-southest-valley',
                content: 'Southeast Valley Properties for Sale',
                url: 'https://www.moniquesells.com/southeast-valley/',
              },
              {
                id: 'tijat-desktop-soutwest-valley',
                content: 'Southwest Valley Properties for Sale',
                url: 'https://www.moniquesells.com/southwest-valley/',
              },
            ],
          },
          {
            id: 'tijat-desktop-reviews',
            content: 'Reviews',
            url: 'https://www.moniquesells.com/reviews/',
          },
          {
            id: 'tijat-desktop-blog',
            content: 'Blog',
            url: 'https://moniquesells-re.com/',
          },
          {
            id: 'tijat-ghost-id',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-tijat-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'hamburger-tijat',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-tijat-mheader',
        headerSectionsClassName: 'on-tijat-header-section-mobile',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-tijat-mheader-menu',
        itemClassName: 'on-tijat-mheader-item',
        menuClassName: '',
        menuLabelActiveClassName: 'on-tijat-mheader-label-active',
        menuItemClassName: '',
        subMenuClassName: 'tijat-submenu',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'tijat-mobile-icon',
            url: 'tel:+1-602-413-8195',
            iconSet: 'fas',
            className: 'mobile',
          },
          {
            id: 'tijat-top-header-phone-number-2',
            content: '602-413-8195',
            url: 'tel:+1-602-413-8195',
          },
        ],
        items: [
          {
            id: 'tijat-desktop-home-item-2',
            content: 'Home',
            url: 'https://www.moniquesells.com/',
          },
          {
            id: 'tijat-cash-offer-mobile',
            content: 'Cash Offer',
            url: 'https://cashoffer.moniquesells.com',
          },
          {
            id: 'tijat-trade-in-mobile',
            content: 'Trade In',
            url: 'https://trade-in.moniquesells.com',
          },
          {
            id: 'tijat-desktop-about-item-2',
            content: 'About',
            menuItems: [
              {
                id: 'tijat-desktop-about-monique-2',
                content: 'Monique',
                url: 'https://www.moniquesells.com/about-monique/',
              },
              {
                id: 'tijat-desktop-about-team-2',
                content: 'About Team',
                url: 'https://www.moniquesells.com/about-team/',
              },
            ],
          },
          {
            id: 'tijat-desktop-listings-2',
            content: 'Listings',
            url: 'https://www.moniquesells.com/listings/',
          },
          {
            id: 'tijat-desktop-properties-2',
            content: 'Properties',
            menuItems: [
              {
                id: 'tijat-desktop-properties-biltmore-arcadia-2',
                content: 'Biltmore - Arcadia Properties for Sale',
                url: 'https://www.moniquesells.com/biltmore-arcadia/',
              },
              {
                id: 'tijat-desktop-properties-northeast-valley-2',
                content: 'Northeast Valley Properties for Sale',
                url: 'https://www.moniquesells.com/northeast-valley/',
              },
              {
                id: 'tijat-desktop-properties-northwest-valley-2',
                content: 'Northwest Valley Properties for Sale',
                url: 'https://www.moniquesells.com/northwest-valley/',
              },
              {
                id: 'tijat-desktop-properties-phoenix-2',
                content: 'Phoenix Properties for Sale',
                url: 'https://www.moniquesells.com/phoenix/',
              },
              {
                id: 'tijat-desktop-southest-valley-2',
                content: 'Southeast Valley Properties for Sale',
                url: 'https://www.moniquesells.com/southeast-valley/',
              },
              {
                id: 'tijat-desktop-soutwest-valley-2.',
                content: 'Southwest Valley Properties for Sale',
                url: 'https://www.moniquesells.com/southwest-valley/',
              },
            ],
          },
          {
            id: 'tijat-desktop-reviews-2',
            content: 'Reviews',
            url: 'https://www.moniquesells.com/reviews/',
          },
          {
            id: 'tijat-desktop-blog-2',
            content: 'Blog',
            url: 'https://moniquesells-re.com/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-tijat-main-footer',
      className: 'on-tijat-footer-section-first',
      sectionClassName: 'on-tijat-mini-footer-container',
      columns: [
        {
          id: 'first-column-tijat',
          className: 'first-column-tijat',
          style: {},
          items: [
            {
              id: 'first-column-tijat-item-1',
              type: 'image',
              className: 'on-tijat-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/tijat/logo-footer.webp',
            },
          ],
        },
        {
          id: 'second-column-tijat',
          className: 'second-column-tijat',
          style: {},
          items: [
            {
              id: 'first-column-tijat-item-4',
              type: 'socialIcons',
              className: 'on-tijat-footer-link-chain-item',
              itemClassName: '',
              items: [
                {
                  id: 'on-tijat-footer-link-chain-item-1',
                  className: 'link-chain',
                  iconTypeClassName: 'fas',
                  iconClassName: 'link',
                  style: {
                    cursor: 'default',
                  },
                },
              ],
            },
            {
              id: 'second-column-tijat-item-1',
              type: 'heading',
              elementType: 'p',
              className: 'on-tijat-footer-heading',
              style: {},
              data: 'Quick Links',
            },
            {
              id: 'second-column-tijat-item-2',
              type: 'menu',
              className: 'second-column-tijat-item-2',
              itemClassName: 'mb-0 on-tijat-footer-menu-popular',
              items: [
                {
                  id: 'second-column-tijat-item-2-home',
                  data: 'Home',
                  url: 'https://www.moniquesells.com/',
                },
                {
                  id: 'second-column-tijat-item-2-about',
                  data: 'About',
                  url: 'https://www.moniquesells.com/about-monique/',
                },
                {
                  id: 'tijat-cash-offer-footer',
                  data: 'Cash Offer',
                  url: 'https://cashoffer.moniquesells.com',
                },
                {
                  id: 'second-column-tijat-item-2-reviews',
                  data: 'Reviews',
                  url: 'https://www.moniquesells.com/reviews/',
                },
                {
                  id: 'tijat-trade-in-footer',
                  data: 'Trade In',
                  url: 'https://trade-in.moniquesells.com',
                },
                {
                  id: 'second-column-tijat-item-2-blog',
                  data: 'Blog',
                  url: 'https://moniquesells-re.com/',
                },
                {
                  id: 'second-column-tijat-item-2-listings',
                  data: 'Listings',
                  url: 'https://www.moniquesells.com/listings/',
                },
                {
                  id: 'second-column-tijat-item-2-home-value',
                  data: 'Home Value',
                  url: 'https://mwalker602.topproducerwebsite.com/home-valuation',
                },
                {
                  id: 'second-column-tijat-item-2-properties',
                  data: 'Properties',
                  url: 'https://www.moniquesells.com/properties/',
                },
              ],
            },
          ],
        },
        {
          id: 'third-column-tijat',
          className: 'third-column-tijat',
          style: {},
          items: [
            {
              id: 'first-column-tijat-item-4',
              type: 'socialIcons',
              className: 'on-tijat-footer-link-chain-item-2-1',
              itemClassName: '',
              items: [
                {
                  id: 'on-tijat-footer-link-chain-item-2-2',
                  className: 'link-chain',
                  iconTypeClassName: 'fas',
                  iconClassName: 'mobile',
                  style: {
                    cursor: 'default',
                  },
                },
              ],
            },
            {
              id: 'second-column-tijat-item-1-2',
              type: 'heading',
              elementType: 'p',
              className: 'on-tijat-footer-heading-2',
              style: {},
              data: 'Contact Us',
            },
            {
              id: 'third-column-tijat-item-1',
              type: 'menu',
              className: '',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'third-column-tijat-item-1-adress',
                  data: '8510 E. Shea Blvd, #100',
                },
                {
                  id: 'third-column-tijat-item-1-location',
                  data: 'Scottsdale, AZ 85260',
                },
                {
                  id: 'third-column-tijat-item-1-map-url',
                  data: '(map)',
                  url: 'https://goo.gl/maps/RytZGmSqEuo',
                },
              ],
            },
            {
              id: 'third-column-tijat-item-2',
              type: 'menu',
              className: '',
              style: {},
              items: [
                {
                  id: 'third-column-tijat-item-1-mobile-p',
                  data: '(P)',
                },
                {
                  id: 'third-column-tijat-item-1-mobile',
                  data: '602-413-8195',
                  url: 'tel:602-413-8195',
                },
              ],
            },
            {
              id: 'third-column-tijat-item-3',
              type: 'menu',
              className: '',
              style: {},
              items: [
                {
                  id: 'third-column-tijat-item-1-number-f',
                  data: '(F)',
                },
                {
                  id: 'third-column-tijat-item-1-number',
                  data: '480-223-6798',
                  url: 'tel:480-223-6798',
                },
              ],
            },
            {
              id: 'third-column-tijat-item-4',
              type: 'menu',
              className: '',
              style: {},
              items: [
                {
                  id: 'third-column-tijat-item-1-mail',
                  data: 'Monique@MoniqueSells.com',
                  url: 'mailto:Monique@MoniqueSells.com',
                },
              ],
            },
            {
              id: 'third-column-tijat-item-5',
              type: 'menu',
              className: '',
              style: {},
              items: [
                {
                  id: 'third-column-tijat-item-1-official-site',
                  data: 'www.MoniqueSells.com',
                  url: 'https://www.moniquesells.com/',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-tijat-second-footer',
      className: 'on-tijat-footer-section-second',
      sectionClassName: 'on-tijat-mini-footer-container-2',
      columns: [
        {
          id: 'second-footer-column-tijat-1',
          className: 'second-footer-column-tijat-1',
          style: {},
          items: [
            {
              id: 'only-column-tijat-item-copyright',
              type: 'heading',
              elementType: 'p',
              className: 'only-column-tijat-item-1',
              data: 'Copyright 2016-2022',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'The Monique Walker Team',
    agentDescription: 'Top agents in the Phoenix Metropolitan Area',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/tijat/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/tijat/hero-bg.jpg',
  },
};
